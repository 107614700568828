<template>
    <div class="main" :style="{ backgroundImage: `url(${playerDetailsBg})` }">
        <div class="injury_content">
            <!-- 导航 -->
            <page-head :src="require('@/assets/images/common/nav-back-black.svg')"  class="nav_back" />
            <!-- 左侧 -->
            <div class="injury_left">
                <people-header :list="peopleData" peopleType="injury" />
                <injuryMap :list="svgData" />
            </div>
            <!-- 右侧 -->
            <div class="injury_right">
                <div class="injury_right_info">
                    <div class="injury_right_row">
                        <div class="injury_right_text">
                            <label>伤病部位</label>
                            <span>{{ injuryData.nativePlace || defaultText }}</span>
                        </div>
                    </div>
                    <div class="injury_right_row">
                        <div class="injury_right_text">
                            <label>受伤严重程度</label>
                            <span>{{ injuryData.injurySeverityMax || defaultText }}</span>
                        </div>
                        <div class="injury_right_text">
                            <label>是否随队训练</label>
                            <span>{{ injuryData.teamTraining ? '是' : '否' }}</span>
                        </div>
                        <div class="injury_right_text injury_right_date">
                            <label>预计恢复时间</label>
                            <span class="akrobatRegular">{{ injuryData.esimatedReturnDate || defaultText }}</span>
                        </div>
                    </div>
                </div>
                <injuryList :list="injuryHistoryData.showList" />
                <!-- 翻页 -->
                <pagination-com
                    class="pagination"
                    :styleNum="{ color: '#787885', opacity: '1' }"
                    :current-page="injuryHistoryData.page"
                    :total-page="injuryHistoryData.totalPage"
                    @changePage="changePage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from "vue-router";
import {defineComponent, onMounted, getCurrentInstance, toRefs, reactive, watch} from 'vue';
import injuryMap from './components/injuryMap.vue';
import injuryList from './components/injuryList.vue';
import { useStore } from "vuex";
export default defineComponent({
    components: {
        injuryMap,
        injuryList
    },
    setup() {
        const route = useRoute()
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const { playerDetailsBg } = proxy.$Config.UI_CONFIG
        const state = reactive({
            playerDetailsBg,
            playerId: route.query.id,
            peopleData: {},
            svgData: [[], [], []],
            injuryData: [],
            query: {
                pageNum: 1,
                pageSize: 2
            },
            serverData: [],
            injuryHistoryData: [],
            defaultText: '-'
        });

        watch(() => store.state.identifyData, (val, old) => {
            // console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                ...state.injuryHistoryData,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
            })
        })
        // 球员信息
        const getDetailsData = async () => {
            const params = {
                id: state.playerId, // 球员id
                dynamic: false
            }
            const { code, data } = await proxy.$server.getUserMsg(params);
            if (code === 200) {
                state.peopleData = data;
            }
        };

        // 伤病
        const getInjuryDetail = async () => {
            const params = {
                playerId: state.playerId, // 球员id
            }
            const { code, data } = await proxy.$server.injuryDetail(params);
            if (code === 200) {
                // 获取所有卡片的svg图
                const injurySvg = data.svg
                let svg1 = []; // 第一张svg
                let svg2 = []; // 第二张svg
                let svg3 = []; // 第三张svg
                for (let item in injurySvg) {
                    svg1.push(...injurySvg[item][0]);
                    svg2.push(...injurySvg[item][1]);
                    svg3.push(...injurySvg[item][2]);

                }
                state.injuryData = data;
                state.svgData = [svg1, svg2, svg3]
            }
        };

        // 伤病历史
        const getInjuryHistory = async () => {
            const params = {
                playerId: state.playerId, // 球员id
            }
            const { code, data } = await proxy.$server.injuryHistory(params);
            if (code === 200) {
                const { pageNum, pageSize } = state.query;
                state.serverData = data;
                state.injuryHistoryData = proxy.$utils.paginationFn(data, pageNum, pageSize);
            }
        }

        const changePage = (page) => {
            const { pageSize } = state.query;
            state.injuryHistoryData = proxy.$utils.paginationFn(state.serverData, page, pageSize);
            console.log(state.injuryHistoryData)
        }

        onMounted(() => {
            getDetailsData()
            getInjuryDetail()
            getInjuryHistory()
        })

        return {
            changePage,
            ...toRefs(state),
        }
    },
})
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: 100%;
    background-size: 100% auto;
    position: relative;
    box-sizing: border-box;
    padding: 60px 40px;

    .injury_content {
        position: relative;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        height: 100%;
        border-radius: 20px;
        background: url("../../assets/images/common/injury-head-bg.png") no-repeat left top;
        background-size: 100% 300px;
        background-color: #FFFFFF;

        /*返回按钮*/
        .nav_back{
            position: absolute;
            top: 32px;
            left: 32px;
            z-index: 3;
            padding: 0;
        }

        .injury_left {
            flex: 1;
            margin-right: 44px;
        }
        .injury_right {
            width: 970px;
            .injury_right_info {
                margin-top: 50px;
                height: 208px;
                box-sizing: border-box;
                .injury_right_row {
                    display: flex;
                    margin-bottom: 24px;
                    .injury_right_text {
                        display: flex;
                        align-items: center;
                        color: #333333;
                        width: 290px;
                        line-height: 45px;
                        label {
                            font-size: 28px;
                            opacity: 0.4;
                        }
                        span {
                            padding-left: 16px;
                            font-size: 32px;
                            opacity: 0.8;
                        }
                    }

                    .injury_right_date {
                        width: 430px;
                        span {
                            font-size: 40px;
                        }
                    }
                }
            }
        }
    }
}
.pagination {
    margin-left: 275px;
    margin-top: 44px;
}
::v-deep .pageBtn{
    background: #B7B7BF !important;
    height: 56px !important;
    line-height: 56px !important;
    &.disable {
        opacity: 1 !important;
        background: #F3F5F9 !important;
        color: #B7B7BF !important;
    }
}
</style>
