<template>
    <div class="injury_map">
        <ul class="injury_map_tab">
            <li
                v-for="(item,index)  in svgDeg"
                :key="index"
                :class="degIndex===index ? 'active' : ''"
                @click="changeDeg(index)"
            >
                {{ item.value }}
            </li>
        </ul>
        <div class="injury_map_main">
            <div class="myDrawing" id="Drawing"></div>
            <img class="injury-img" :src="setImg" alt />
        </div>

    </div>
</template>

<script>
import { SVG } from '@svgdotjs/svg.js'
import { defineComponent, computed, toRefs, reactive, watch, nextTick } from 'vue';
export default defineComponent({
    props: {
        list: {
            type: Object,
            default: () => { }
        },
    },
    setup(props) {
        // 伤病图角度
        const setImg = computed(() => {
            const obj = [
                'https://acme-public.oss-cn-beijing.aliyuncs.com/guoan/icons/injury/injuryBodyFront.png',
                'https://acme-public.oss-cn-beijing.aliyuncs.com/guoan/icons/injury/injuryBodyLeftFront.png',
                'https://acme-public.oss-cn-beijing.aliyuncs.com/guoan/icons/injury/injuryBodyRightFront.png'
            ]
            const res = obj[state.degIndex]
            return res
        });
        watch(
            () => props.list,
            (newVal) => {
                if (newVal) {
                    nextTick(() => {
                        let list = newVal[state.degIndex]
                        initSvg(list);
                    })
                }
            },
        );
        const state = reactive({
            darwing: '',
            canvasHistory: [],
            circleR: 15,
            lineWidth: 1,
            fillStyle: "#65B168",
            strokeStyle: "#65B168",
            svgWidth: 451,  //svg图默认宽度
            svgHeight: 388, //svg图默认高度
            allSvg: {},
            svgDeg: [
                {
                    key: 'svgFront',
                    value: '0°'
                },
                {
                    key: 'svgSide',
                    value: '45°'
                },
                {
                    key: 'svgNegative',
                    value: '-45°'
                }
            ], //svg旋转度数
            degIndex: 0
        });

        const changeDeg = (index) => {
            state.degIndex = index;
            console.log(props.list)
            let list = props.list[index]
            initSvg(list);
        }

        //初始化svg画板
        const initCanvas = () => {
            const div = document.getElementById("Drawing");
            state.svgWidth = div.offsetWidth;
            state.svgHeight = div.offsetHeight;
            div.innerHTML = "";
            state.darwing = null
            state.canvasHistory = [];
            state.darwing = SVG().addTo('#Drawing').size('100%', '100%')
        }
        //获取后台svg，回显
        const initSvg = async(data) => {
            await initCanvas();
            if (data && data.length > 0) {
                state.canvasHistory = [];
                data.forEach((item) => {
                    let svg = '';
                    if (parseDom(item).childNodes[0].getAttribute('id').indexOf('Circle') > -1) {
                        //圆
                        let cx = parseDom(item).childNodes[0].getAttribute('cx');
                        let cy = parseDom(item).childNodes[0].getAttribute('cy');
                        let stroke = parseDom(item).childNodes[0].getAttribute('stroke');
                        let fill = parseDom(item).childNodes[0].getAttribute('fill');
                        //当前宽度 348    原来宽高441*373
                        let newCx, newCy;
                        if (cx > 1) {
                            newCx = cx / 455 * state.svgWidth;
                            newCy = cy / 388 * state.svgHeight;
                        } else {
                            newCx = cx * state.svgWidth;
                            newCy = cy * state.svgHeight;
                        }
                        svg = state.darwing.circle(state.circleR)
                            .attr('stroke-width', state.lineWidth).attr("stroke", stroke).attr("fill", fill)
                            .attr("fill-opacity", '0.8')
                            .attr({ cx: newCx, cy: newCy });
                    }
                    let group = state.darwing.group();
                    group.add(svg);
                    state.canvasHistory.push(group);
                })
            }
        }
        //string转dom
        const parseDom = (arg) => {
            let objE = document.createElement("div");
            objE.innerHTML = arg;
            return objE.childNodes[0];
        }

        return {
            setImg,
            changeDeg,
            ...props,
            ...toRefs(state),
        }
    },
})
</script>

<style scoped lang="scss">
.injury_map {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    margin-left: 48px;
    &_tab {
        width: 88px;
        height: 373px;
        padding: 6px;
        background: #39459F;
        border-radius: 200px;
        border: 2px solid rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: center;
        align-content: space-between;
        flex-wrap: wrap;
        box-sizing: border-box;
        > li {
            width: 100%;
            height: 121px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 40px;
            font-size: 36px;
            line-height: 45px;
            font-family: akrobatBold;
        }
        .active {
            background: rgba(217, 185, 255, 0.3);
        }
    }
    &_main {
        position: relative;
        background: url("~@i/injury/svg_shadow.png") no-repeat center bottom 40px;
        background-size: 100% 40px;
        background-position-y: bottom;

        .injury-img {
            position: relative;
            top: 0;
            width: 609.03px;
            height: 538.22px;
        }
        .myDrawing {
            position: absolute;
            top: 0;
            width: 609.03px;
            height: 538.22px;
            z-index: 2;
        }
    }
    // &_icons {
    //     height: 20px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     margin: 24px 0;
    //     > li {
    //         display: flex;
    //         align-items: center;
    //         &:first-child {
    //             margin-right: 40px;
    //         }
    //         i {
    //             width: 14px;
    //             height: 14px;
    //             border-radius: 50%;
    //             margin-right: 6px;
    //         }
    //         span {
    //             font-size: 14px;
    //             line-height: 20px;
    //             font-family: SysFontR;
    //             color: #3a3a3a;
    //         }
    //     }
    //     .healthy {
    //         background-color: rgba(101, 177, 104, 0.8);
    //     }
    //     .injury {
    //         background-color: rgba(249, 92, 81, 0.8);
    //     }
    // }
}
</style>
