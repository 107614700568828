<template>
    <div v-if="cardList && cardList.length" class="card_list">
        <div class="line"></div>
        <div class="card_item" v-for="(contItem, contIndex) in cardList" :key="contItem.id">
            <div class="item_state">
                <!-- 康复icon -->
                <img
                    v-if="contItem.recure"
                    :src="stateMap['rehab'].icon"
                    :alt="stateMap['rehab'].label"
                    class="item_icon"
                />
                <!-- 伤病icon/恢复icon/治疗icon-->
                <img
                    v-else
                    :src="stateMap[contItem.recordType].icon"
                    :alt="stateMap[contItem.recordType].label"
                    class="item_icon"
                />
                <div class="item_time akrobatRegular">{{ contItem.date }}</div>
            </div>
            <!-- 内容 -->
            <div class="content">
                <div class="player_info">
                    <div class="player_left">
                        <div class="player_img">
                            <img :src="contItem.creatorAvatar" alt />
                        </div>
                        <div class="player_name">
                            <p class="name">{{ contItem.creatorName }}</p>
                            <p class="time akrobatRegular">{{ momentFn(contItem.createTime) }}&nbsp;AM</p>
                        </div>
                    </div>
                    <span
                        class="player_right"
                        :style="{
                            borderColor: $utils.hexToRgb(stateMap[contItem.recordType].color, 1),
                            background: $utils.hexToRgb(stateMap[contItem.recordType].color, .8)
                        }"
                    >
                        {{ stateMap[contItem.recordType].label }}
                        <i
                            :style="{ '--color': $utils.hexToRgb(stateMap[contItem.recordType].color, 1), borderRightColor: $utils.hexToRgb(stateMap[contItem.recordType].color, .8)}"
                        ></i>
                    </span>
                </div>
                <!-- 伤病状态 -->
                <div v-if="contItem.recordType === 'injury'" class="injury_state_cont">
                    <div class="label_item">
                        <span class="label_name">受伤日期：</span>
                        <span class="label_val akrobatRegular">{{ contItem.date }}</span>
                    </div>
                    <div class="label_item">
                        <span class="label_name">受伤位置：</span>
                        <span class="label_val">{{ contItem.position }}</span>
                    </div>
                    <div class="label_item">
                        <span class="label_name">受伤原因：</span>
                        <span class="label_val">
                            <pre>{{ contItem.reason }}</pre>
                        </span>
                    </div>
                    <div class="label_item">
                        <span class="label_name">新伤旧伤：</span>
                        <span class="label_val">{{ contItem.newInjury ? '新伤' : '旧伤'}}</span>
                    </div>
                    <div class="label_item">
                        <span class="label_name">随队训练：</span>
                        <span class="label_val">{{ contItem.teamTraining ? 'Y' : 'N' }}</span>
                    </div>
                    <div class="label_item">
                        <span class="label_name">严重程度：</span>
                        <span class="label_val akrobatRegular">{{ contItem.severity }}</span>
                    </div>
                </div>
                <!-- 治疗状态 -->
                <div v-if="contItem.recordType === 'treatment'" class="injury_state_cont">
                    <div class="label_item">
                        <span class="label_name">治疗方案：</span>
                        <span class="label_val">
                            <pre>{{ contItem.treatment }}</pre>
                        </span>
                    </div>
                    <div class="label_item">
                        <span class="label_name">用药记录：</span>
                        <span class="label_val">
                            <pre>{{ contItem.medication }}</pre>
                        </span>
                    </div>
                    <div class="label_item">
                        <span class="label_name">预计恢复时间：</span>
                        <span class="label_val akrobatRegular treatment">{{ contItem.esimatedReturnDate || defaultText }}</span>
                    </div>
                </div>
                <!-- 康复状态 -->
                <div v-if="contItem.recure" class="injury_state_cont">
                    <div class="label_item">
                        <span class="label_name">康复时间：</span>
                        <span class="label_val akrobatRegular">{{ contItem.recureDate || defaultText }}</span>
                    </div>
                    <div class="label_item">
                        <span class="label_name">预期康复时间：</span>
                        <span class="label_val akrobatRegular">{{contItem.esimatedReturnDate || defaultText }}</span>
                    </div>
                </div>
                <!-- 恢复状态 -->
                <div v-if="contItem.recordType === 'recovery'" class="injury_state_cont">
                    <div class="label_item">
                        <span class="label_name">治疗方案：</span>
                        <span class="label_val">
                            <pre>{{ contItem.treatment }}</pre>
                        </span>
                    </div>
                    <div class="label_item">
                        <span class="label_name">医生：</span>
                        <span class="label_val">
                            <pre>{{ contItem.doctorName || defaultText }}</pre>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, getCurrentInstance, toRefs, reactive, watch } from 'vue';
export default defineComponent({
    props: {
        list: {
            type: Array,
            default: () => { }
        },
    },
    setup(props) {
        // 伤病图角度
        watch(
            () => props.list,
            (newVal) => {
                if (newVal) {
                    state.cardList = newVal
                }
            },
        );
        const { proxy } = getCurrentInstance()
        const state = reactive({
            cardList: [],
            stateMap: {        // 状态map映射关系
                injury: {
                    label: '伤病',
                    color: '#F95C51',
                    rgb: 'rgb(249, 92, 81)',
                    icon: require(`@/assets/images/injury/injury_injury_icon.svg`)
                },
                treatment: {
                    label: '治疗',
                    color: '#EA7F40',
                    rgb: 'rgb(234, 127, 64)',
                    icon: require(`@/assets/images/injury/injury_treatment_icon.svg`)
                },
                rehab: {
                    label: '康复',
                    color: '#60BF52',
                    rgb: 'rgb(96, 191, 82)',
                    icon: require(`@/assets/images/injury/injury_rehab_icon.svg`)
                },
                recovery: {
                    label: '恢复',
                    color: '#00ACA2',
                    rgb: 'rgb(0, 172, 162)',
                    icon: require(`@/assets/images/injury/injury_recovery_icon.svg`)
                }
            }
        });

        const momentFn = (time) => {
            return proxy.$moment(time).format('HH:mm')
        }
        return {
            momentFn,
            ...props,
            ...toRefs(state),
        }
    },
})
</script>

<style scoped lang="scss">
.card_list {
    width: 930px;
    height: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    .line {
        position: absolute;
        top: 0;
        width: 100%;
        opacity: 0.4;
        border-bottom: 3px dashed #BDBDC3;
    }
    .card_item {
        width: 453px;
        .item_state {
            position: relative;
            .item_icon {
                position: absolute;
                top: -36px;
                left: 50%;
                transform: translateX(-50%);
                width: 24px;
                height: 24px;
            }
            .item_time {
                margin-top: 24px;
                font-size: 32px;
                color: #333333;
                opacity: 0.6;
                text-align: center;
                margin-bottom: 14px;
            }
        }
        // 内容
        .content {
            width: 453px;
            height: 494px;
            padding: 24px;
            box-sizing: border-box;
            background: rgba(197, 119, 0, 0.12);
            border-radius: 8px;
            .player_info {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                .player_left {
                    display: flex;
                    .player_img {
                        width: 80px;
                        height: 80px;
                        border-radius: 50%;
                        margin-right: 8px;
                        overflow: hidden;
                        background-color: #724EAC;
                        img {
                            width: 100%;
                        }
                    }
                    .player_name {
                        height: 80px;
                        color: #333333;
                        .name {
                            font-size: 28px;
                            line-height: 39px;
                            opacity: 0.8;
                        }
                        .time {
                            margin-top: 8px;
                            font-size: 28px;
                            line-height: 35px;
                            opacity: 0.4;
                        }
                    }
                }
                .player_right {
                    position: relative;
                    padding: 0 14px;
                    height: 39px;
                    line-height: 34px;
                    box-sizing: border-box;
                    text-align: center;
                    border-width: 2px;
                    border-left-width: 0;
                    border-style: solid;
                    color: #FFFFFF;
                    font-weight: 500;
                    font-size: 26px;
                    i {
                        position: absolute;
                        left: -15px;
                        top: 0;
                        width: 0;
                        height: 0;
                        border-top: 18px solid transparent;
                        border-right-width: 15px;
                        border-right-style: solid;
                        border-bottom: 20px solid transparent;
                        &::before {
                            content: '';
                            height: 25px;
                            width: 30px;
                            border-left: var(--color) solid 3px;
                            position: absolute;
                            right: -12px;
                            top: -32px;
                            border-radius: 1px;
                            transform: rotate(222deg);
                        }
                        &::after {
                            content: '';
                            height: 25px;
                            width: 30px;
                            border-left: var(--color) solid 3px;
                            position: absolute;
                            right: -35px;
                            top: -13px;
                            border-radius: 1px;
                            transform: rotate(320deg);
                        }
                    }
                }
            }

            .injury_state_cont {
                margin-top: 32px;
                .label_item {
                    display: flex;
                    margin-bottom: 12px;
                    line-height: 34px;
                    color: #333333;
                    font-size: 24px;
                    .label_name {
                        margin-right: 5px;
                        opacity: 0.6;
                    }
                    .label_val {
                        flex: 1;
                        display: flex;
                        box-sizing: border-box;
                        flex-wrap: wrap;
                        word-break: break-all;
                        opacity: 0.9;
                        pre{
                            width: 280px;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            white-space:nowrap;
                        }
                    }
                    .akrobatRegular {
                        font-size: 30px;
                        line-height: 36px;
                    }
                    // 回复时间
                    .treatment {
                        color: #EA7F40;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
</style>
